import React, { } from "react";
import { RendererFactory, Serializer } from "survey-core";
import { ReactQuestionFactory, SurveyQuestionCheckbox } from "survey-react-ui";

import style from './_index.module.scss'
import { trim } from "lodash";

export class CheckboxVOIQuestion extends SurveyQuestionCheckbox {

    constructor(props: any) {
        super(props);

        (this as any).getBody = (cssClasses: any) => {
            let filteredItems = this.question.bodyItems;

            if (this.question.onlyInclude?.length) {
                const onlyInclude = this.question.onlyInclude?.split(',').map((o: string) => trim(o) ? Number(trim(o)) : null) || [];

                if (onlyInclude.length > 0) {
                    filteredItems = this.question.bodyItems.filter((item: any) => onlyInclude.includes(item.id));
                }
            }

            return (
                <div className={style.radio_group_voi}>
                    {this.getItems(cssClasses, filteredItems)}
                </div>
            )
        }

        (this as any).renderItem = (item: any, isFirst: boolean, cssClasses: any, index?: string) => {
            const imageURL = `https://cdn.latitudewebservices.com/vehicles/images/${item.image}`;
            const inputId = `input_${this.question.name}_${item.id}`;

            let isChecked = this.question.isItemSelected(item);

            const handleOnChange = (event: any) => {
                this.question.clickItemHandler(item, event.target.checked);
            }

            return (
                <div
                    role="presentation"
                    className={isChecked ? `${style.voi_option} ${style.voi_option_active}` : `${style.voi_option}`}
                    onClick={(e) => {
                        const checkbox = document.getElementById(`checkbox_${inputId}`);
                        if (checkbox && e.target !== checkbox) {
                            checkbox.click();
                        }
                    }}
                    key={item.id}
                    id={inputId}
                >
                    <img alt={item.title} src={imageURL} className={style.vehicle_img} />
                    <label htmlFor={inputId}>
                        {item.title.replace("-", "‑")}
                        <input
                            role="option"
                            id={`checkbox_${inputId}`}
                            disabled={!this.question.getItemEnabled(item)}
                            name={this.question.name + item.id}
                            type="checkbox"
                            value={item.value}
                            checked={isChecked}
                            onChange={handleOnChange}
                            onClick={(e) => e.stopPropagation()} // prevent event from bubbling up to the div
                        />
                    </label>
                </div>
            )
        }
    }
}

// only add image property if renderAs is voi
Serializer.addProperty("itemvalue", {
    name: "image",
    displayName: "Image",
    type: "file",
    visibleIf: (obj) => {
        return obj.locOwner.renderAs === "voi";
    }
});

ReactQuestionFactory.Instance.registerQuestion(
    "sv-checkbox-voi",
    (props) => {
        return React.createElement(CheckboxVOIQuestion, props);
    }
);

RendererFactory.Instance.registerRenderer(
    "checkbox",
    "voi",
    "sv-checkbox-voi"
);
