import { useEffect, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { FunctionFactory } from 'survey-core';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Loader } from "@progress/kendo-react-indicators";
import * as Sentry from "@sentry/react";
import showdown from 'showdown';

import auth from '../services/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

// these are the custom surveyjs renderers
// while not referenced in the code they have to be imported to be registered
import { CheckboxVOIQuestion } from "../surveysjs_renderers/CheckboxVOI";
import { RadioGroupRowQuestion } from "../surveysjs_renderers/RadioButtonButton";
import { SurveyBookeoQuestion } from "../surveysjs_renderers/Bookeo";
import { EmailTextInput } from "../surveysjs_renderers/EmailTextInput";

import "survey-core/defaultV2.min.css";

import "./Surveys.css";
import { prepareForSurvey, prepareSurveyOnQuestionAdded } from "../helpers/surveyTemplatesAll";

function validateEmail() {
    console.log('[validateEmail]', this.question.value);
    const email = this.question.value;

    this.question.setPropertyValue('didYouMean', "");

    if (!email) {
        this.returnResult();
        return;
    }

    fetch('https://us-central1-latitude-lead-system.cloudfunctions.net/validateEmail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
    }).then(response => {
        response.json().then((res) => {
            const { results } = res;

            let valid = true;
            console.log('this.survey', this.survey);
            console.log('this.question', this.question);
            console.log('validateEmail res', res);

            // bad emails are rejected
            if (results?.valid === false) {
                valid = false;
            }

            // disposable email services are rejected
            if (results?.is_disposable === true) {
                valid = false;
            }

            // reject delivery_confidence below 20
            if (results?.delivery_confidence < 20) {
                valid = false;
            }

            // typos are rejected with correction
            if (results.did_you_mean) {
                valid = true;
                this.question.setPropertyValue('didYouMean', results.did_you_mean);

                console.log('this.question after', this.question.didYouMean);
            }

            this.returnResult(valid);
        });
    }).catch(err => {
        Sentry.captureException(err);
        alert(err);
    });
}

FunctionFactory.Instance.register("validateEmail", validateEmail, true);

const extractUTM = () => {
    // Get the UTM parameters from the URL
    var search = window.location.search;
    var params = new URLSearchParams(search);

    const utm = {};

    // Loop through each UTM parameter
    for (var [key, value] of params.entries()) {
        // Check if the parameter is "utm_source", "utm_medium", "utm_campaign", "utm_term", or "utm_content"
        if (["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"].includes(key)) {
            utm[key] = value;
        }
    }

    return utm;
}

function SurveyComponent() {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [user] = useAuthState(auth);
    const [thisSurvey, setThisSurvey] = useState();
    const [thisError, setThisError] = useState();

    const converter = new showdown.Converter({
        openLinksInNewWindow: true,
    });

    const handleSurveyStarting = (sender, options) => {
        console.log('handleSurveyStarting', options);
    }

    useEffect(() => {
        fetch('https://us-central1-latitude-lead-system.cloudfunctions.net/getSurvey', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ eventID: params.eventID, preSurveyID: location.state?.preSurveyID }),
        }).then(response => {
            response.json().then((res) => {
                if (res.success) {
                    const themeJSON = JSON.parse(res.event.theme);

                    document.body.style.backgroundColor = themeJSON.cssVariables['--sjs-editor-background'];

                    document.title = res.event.name;
                    // if the favicon is defined
                    if (themeJSON.favicon) {
                        var link = document.querySelector("link[rel~='icon']");
                        link.href = themeJSON.favicon;
                    }

                    if (res.event._preEventID && !location.state?.preSurveyID) {
                        // this is a post-event survey, sign people out
                        navigate('./out');
                    }

                    const surveyJSON = JSON.parse(res.event.questions);

                    if (res.event.disabled && !user) {
                        surveyJSON.showCompleteButton = false;
                        surveyJSON.pages = [
                            {
                                "elements": [
                                    {
                                        "type": "html",
                                        "html": `<h4>${res.event.disabled}</h4>`
                                    }
                                ]
                            }
                        ]
                    }

                    const survey = new Model(surveyJSON);
                    survey.applyTheme(themeJSON);

                    prepareForSurvey(survey);

                    survey.onAfterRenderSurvey.add((sender) => {
                        sender.showCompleteButton = surveyJSON.showCompleteButton;
                        sender.showNavigationButtons = surveyJSON.showNavigationButtons;

                        sender.setValue('start_time', new Date());
                        sender.setValue('survey_date', new Date());
                        sender.setValue('event_id', params.eventID);
                        sender.setValue('app_version', 'surveyjs_1.0');
                        sender.setValue('abandoned', 0);
                        sender.setValue('_utm', extractUTM());
                        sender.setValue('_referrer', window.frames?.top?.document?.referrer);
                        sender.setValue('_language', window.navigator?.language);
                        sender.setValue('device_id', window.navigator?.userAgent);
                        sender.setValue('_screenWidth', window.screen?.width);
                        sender.setValue('_offset', (new Date()).getTimezoneOffset());
                        sender.setValue('_timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
                        console.log('defaults set', sender.getValue('survey_date'));
                    });

                    survey.onServerValidateQuestions.add((sender, options) => {
                        // loop & look for Bookeo
                        survey.getAllQuestions().forEach(question => {
                            console.log('question', question.name, question.getType());
                            if (question.getType() === "bookeo" && options.data[question.name]) {
                                const bookeoData = {
                                    bookeoKey: sender.getQuestionByName(question.name).getPropertyValue("bookeoKey"),
                                    customFieldId: sender.getQuestionByName(question.name).getPropertyValue("customFieldId"),
                                    seats: sender.getQuestionByName(question.name).getPropertyValue("seats"),
                                    productId: sender.getQuestionByName(question.name).getPropertyValue("productId"),
                                    previousHoldId: options.data[question.name].previousHoldId,
                                    eventId: options.data[question.name].eventId,
                                    firstName: options.data["first_name"],
                                    lastName: options.data["last_name"],
                                    emailAddress: options.data["email"],
                                    phone: options.data["phone"],
                                    type: "mobile",
                                    customData: JSON.stringify({}),
                                }

                                fetch('https://us-central1-latitude-lead-system.cloudfunctions.net/makeBookeoBooking', {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' },
                                    body: JSON.stringify(bookeoData),
                                }).then(response => {
                                    response.json().then((data) => {
                                        if (!response.ok) {
                                            options.errors[question.name] = "Booking Failed, please select a different time.";
                                            sender.getQuestionByName(question.name).setPropertyValue("productId", sender.getQuestionByName(question.name).getPropertyValue("productId"));
                                            console.error('Bookeo API Booking Error', data);
                                            options.data[question.name] = null;
                                            options.complete();
                                        }

                                        const val = options.data[question.name];

                                        val.bookingNumber = data.bookingNumber;
                                        options.data[question.name] = val;
                                    });
                                }).catch(err => {
                                    console.error(err);
                                    options.errors[question.name] = "Booking Failed, please select a different time.";
                                    sender.getQuestionByName(question.name).clearValue();
                                    sender.getQuestionByName(question.name).setPropertyValue("productId", sender.getQuestionByName(question.name).getPropertyValue("productId"));
                                    options.complete();
                                });
                            }
                        });

                        options.complete();
                    });

                    survey.onComplete.add(async (sender, options) => {
                        console.log('onComplete', options);
                        const originalMesage = sender.completedHtml;
                        console.log('originalMesage', originalMesage);
                        sender.completedHtml = "<h3>Saving...</h3>";
                        options.showDataSaving('Saving...');

                        let surveyData = sender.data;

                        // set some default hidden properties
                        surveyData['_preSurveyID'] = location.state?.preSurveyID ? location.state.preSurveyID : null;
                        surveyData['_checkedIn'] = null;
                        surveyData['_checkedOut'] = null;
                        surveyData['_claimed'] = null;
                        surveyData['_used'] = null;
                        surveyData['_email'] = null;
                        surveyData['_sms'] = null;
                        surveyData['_exported'] = null;
                        surveyData['end_time'] = new Date();

                        survey.getAllQuestions().forEach(question => {
                            surveyData[question.valueName || question.name] = (typeof question.value === 'undefined' || question.value === null) ? null : question.value;
                        });

                        // save survey
                        fetch('https://us-central1-latitude-lead-system.cloudfunctions.net/saveSurvey', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                eventID: params.eventID,
                                survey: surveyData,
                            }),
                        }).then((res) => {
                            if (res.ok) {
                                return res.json();
                            }

                            Sentry.captureException(new Error(res.statusText));

                            throw new Error(res.statusText);
                        }).then(res => {
                            console.log('saved to firestore', res);

                            options.showDataSavingSuccess();
                            sender.completedHtml = originalMesage;
                            if (location.state?.preSurveyID) {
                                setTimeout(() => {
                                    navigate('./out');
                                }, 3000);
                            }
                        }).catch(err => {
                            console.error('error', err);
                            Sentry.captureException(err);
                            options.showDataSavingError();
                        });

                    });

                    prepareSurveyOnQuestionAdded(null, { survey });

                    setThisSurvey(survey);
                } else {
                    setThisError(res);
                }
            });
        }).catch(err => {
            Sentry.captureException(err);
            alert(err);
        });
    }, [user]);

    return (
        thisSurvey ?
            <Survey model={thisSurvey} /> :
            thisError ? <h2>{thisError.message}</h2> : <Loader type="converging-spinner" size="large" />
    )
}

export default SurveyComponent;