import { useEffect, useState } from "react";
import { Model, FunctionFactory } from "survey-core";
import { Survey } from "survey-react-ui";
import * as SurveyCore from "survey-core";
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { Loader } from "@progress/kendo-react-indicators";
import * as Sentry from "@sentry/react";

import { themeJSON } from "../themes/surveyJS/ford";
import { baseSurvey, incentiveThanks } from "./ExperienceSurvey";

import { CheckboxVOIQuestion } from "../surveysjs_renderers/CheckboxVOI";
import { RadioGroupRowQuestion } from "../surveysjs_renderers/RadioButtonButton";

import "survey-core/defaultV2.min.css";

import "./Surveys.css";
import { prepareForSurvey, prepareSurveyOnQuestionAdded } from "../helpers/surveyTemplatesAll";

import broncoQuizJSON from "./BroncoQuizData.json";

SurveyCore.setLicenseKey(
    "NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wMS0wNA=="
);

const eventID = "BroncoQuizDraft";

const survey = new Model(broncoQuizJSON);
survey.applyTheme(themeJSON);
prepareForSurvey(survey);

const extractUTM = () => {
    // Get the UTM parameters from the URL
    var search = window.location.search;
    var params = new URLSearchParams(search);

    const utm = {};

    // Loop through each UTM parameter
    for (var [key, value] of params.entries()) {
        // Check if the parameter is "utm_source", "utm_medium", "utm_campaign", "utm_term", or "utm_content"
        if (["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"].includes(key)) {
            utm[key] = value;
        }
    }

    return utm;
}

survey.onAfterRenderSurvey.add((sender) => {
    // sender.showCompleteButton = surveyJSON.showCompleteButton;
    // sender.showNavigationButtons = surveyJSON.showNavigationButtons;

    sender.setValue('start_time', new Date());
    sender.setValue('survey_date', new Date());
    sender.setValue('event_id', eventID);
    sender.setValue('app_version', 'surveyjs_1.0');
    sender.setValue('abandoned', 0);
    sender.setValue('_utm', extractUTM());
    sender.setValue('_referrer', window.frames?.top?.document?.referrer);
    sender.setValue('_language', window.navigator?.language);
    sender.setValue('device_id', window.navigator?.userAgent);
    sender.setValue('_screenWidth', window.screen?.width);
    sender.setValue('_offset', (new Date()).getTimezoneOffset());
    sender.setValue('_timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
    console.log('defaults set', sender.getValue('survey_date'));
});

survey.onComplete.add((sender, options) => {
    // calculate the score & send it to firebase with a timestamp
    const correctAnswers = sender.getCorrectAnswerCount();
    const totalQuestions = sender.getAllQuestions().length;

    let surveyData = sender.data;

    surveyData["_correct_answers"] = correctAnswers;
    surveyData["_total_questions"] = totalQuestions;

    // set some default hidden properties
    surveyData['_preSurveyID'] = null;
    surveyData['_checkedIn'] = null;
    surveyData['_checkedOut'] = null;
    surveyData['_claimed'] = null;
    surveyData['_used'] = null;
    surveyData['_email'] = null;
    surveyData['_sms'] = null;
    surveyData['_exported'] = null;
    surveyData['end_time'] = new Date();
    surveyData['end_time_seconds'] = Math.floor(new Date().getUTCMilliseconds() / 1000);

    sender.getAllQuestions().forEach(question => {
        surveyData[question.valueName || question.name] = (typeof question.value === 'undefined' || question.value === null) ? null : question.value;
    });

    // save survey
    fetch('https://us-central1-latitude-lead-system.cloudfunctions.net/saveSurvey', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            eventID: eventID,
            survey: surveyData,
        }),
    }).then((res) => {
        if (res.ok) {
            return res.json();
        }

        Sentry.captureException(new Error(res.statusText));

        throw new Error(res.statusText);
    }).then(res => {
        console.log('saved to firestore', res);

        if (correctAnswers > 0) {
            // https://getbroncorank-erqibiidsa-uc.a.run.app/getBroncoRank?eventID=BroncoQuizDraft&endTimeSeconds=1724889600
            fetch(`https://getbroncorank-erqibiidsa-uc.a.run.app/getBroncoRank?correctAnswers=${correctAnswers}`)
                .then(res => res.json())
                .then(data => {
                    console.log('leaderboard', data);
                    if (data.percentileRank > 0) {
                        sender.completedHtml = `
                            <h3>You scored in the top ${data.percentileRank}% of people in the last hour!</h3>
                            <h4>You got ${correctAnswers} out of ${totalQuestions} correct!
                            ${correctAnswers < totalQuestions && `<br /><br /><a href="./bronco?t=${new Date().getTime()}">Click here to try again!</a>`}
                            </h4>
                        `;
                    } else {
                        sender.completedHtml = `
                            <h4>You got ${correctAnswers} out of ${totalQuestions} correct.<br /><br /><a href="./bronco?t=${new Date().getTime()}">Click here to try again!</a></h4>
                        `;
                    }

                    options.showDataSavingSuccess();
                }).catch(err => {
                    console.error('error', err);
                    Sentry.captureException(err);
                    options.showDataSavingSuccess();
                });
        } else {
            options.showDataSavingSuccess();
        }
    }).catch(err => {
        console.error('error', err);
        Sentry.captureException(err);
        options.showDataSavingError();
    });
});

prepareSurveyOnQuestionAdded(null, { survey });


function BroncoSurvey() {
    return (
        <Survey model={survey} />
    )
}

export default BroncoSurvey;