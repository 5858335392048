import { useEffect, useState } from 'react';

import { useNavigate, useParams } from "react-router-dom";
import { getFirestore, doc, getDoc, collection, query, getDocs, where, orderBy, Query, Timestamp, FirestoreDataConverter, DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { useAuthState } from 'react-firebase-hooks/auth';

import auth from '../../services/auth';
import app from '../../services/firebase';

import { Model, Question, slk, SurveyModel, ITheme } from "survey-core";
import { SurveyCreator } from "survey-creator-react";

import 'survey-analytics/survey.analytics.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { useCollectionData } from 'react-firebase-hooks/firestore';

slk(
    "NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wNy0xOSwyPTIwMjUtMDctMTksND0yMDI1LTA3LTE5"
);

const EEventConverter: FirestoreDataConverter<ExpanseEvent> = {
    toFirestore(event: ExpanseEvent): DocumentData {
        return {
            ...event,
            preRegDate: event.preRegDate ? Timestamp.fromDate(event.preRegDate) : undefined,
            startDate: Timestamp.fromDate(event.startDate),
            endDate: Timestamp.fromDate(event.endDate),
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): ExpanseEvent {
        const data = snapshot.data(options);
        return {
            id: snapshot.id,
            _preEventID: data._preEventID,
            checkInDisplay: data.checkInDisplay,
            confirmationEmail: data.confirmationEmail,
            disabled: data.disabled,
            preRegDate: data.preRegDate?.toDate(),
            startDate: data.startDate.toDate(),
            endDate: data.endDate.toDate(),
            name: data.name,
            questions: data.questions,
            reminderEmail: data.reminderEmail,
            thankYouEmail: data.thankYouEmail,
            thanks: data.thanks,
            theme: data.theme,
        };
    },
};


function AdminScreen() {
    const navigate = useNavigate();
    const [user, userLoading, userError] = useAuthState(auth);
    const [eventsQuery, setEventsQuery] = useState<Query>();
    const [events, eventsLoading, eventsError] = useCollectionData(eventsQuery);

    useEffect(() => {
        console.error(userError);
    }, [userError]);

    const db = getFirestore(app);

    useEffect(() => {
        if (userLoading) return;

        if (!user) {
            navigate('./login');
        }

        // get the event
        const eventsCollection = collection(db, "events").withConverter(EEventConverter);
        setEventsQuery(query(eventsCollection, orderBy("startDate", "desc")));
    }, [userLoading]);

    return (
        <div>
            <h1>Events</h1>

            <a href="/admin/event/new">New Event</a>

            {eventsLoading && <p>Loading...</p>}

            {eventsError && <p>Error: {eventsError.message}</p>}

            {events?.map((event) => (
                <div key={event.id}>
                    <p><a href={`/admin/event/${encodeURIComponent(event.id)}`}>{event.id}</a> {event.startDate.toLocaleDateString()} - {event.endDate.toLocaleDateString()} {event.name}</p>
                </div>
            ))}
        </div>
    );
}

export default AdminScreen;
