import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import auth from '../services/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import db from '../services/db';
import * as Sentry from "@sentry/react";
import { CollectionReference, Query, DocumentData, DocumentReference, FirestoreDataConverter, collection, query, where, orderBy, updateDoc, Timestamp } from 'firebase/firestore';

import JSONPretty from 'react-json-pretty';
import {
  ListView,
  ListViewItemProps,
} from "@progress/kendo-react-listview";
import { Avatar } from "@progress/kendo-react-layout";
import { SvgIcon } from "@progress/kendo-react-common";
import { userIcon } from "@progress/kendo-svg-icons";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import _ from 'lodash';

type CheckUser = {
  id: string;
  ref: DocumentReference<DocumentData>;
  surveyDate: Date;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  otherAnswers: any;
  _preSurveyID: string;
  _checkedIn: Date;
  _checkedOut: Date;
  _claimed: Date;
  _used: Date;
  _email: any;
  _sms: any;
  _exported: any;
}

const checkUserConverter: FirestoreDataConverter<CheckUser> = {
  toFirestore(checkUser: CheckUser): DocumentData {
    return {
      first_name: checkUser.first_name,
      last_name: checkUser.last_name,
      email: checkUser.email,
      phone: checkUser.phone,
      _preSurveyID: checkUser._preSurveyID,
      _checkedIn: checkUser._checkedIn ? Timestamp.fromDate(checkUser._checkedIn) : null,
      _checkedOut: checkUser._checkedOut ? Timestamp.fromDate(checkUser._checkedOut) : null,
      _claimed: checkUser._claimed ? Timestamp.fromDate(checkUser._claimed) : null,
      _used: checkUser._used ? Timestamp.fromDate(checkUser._used) : null,
      _email: checkUser._email,
      _sms: checkUser._sms,
      _exported: checkUser._exported,
    };
  },
  fromFirestore(snapshot, options): CheckUser {
    const data = snapshot.data(options);
    console.log('data', data.first_name, data.last_name, data.email, data.phone);
    console.log('data', data);

    return {
      id: snapshot.id,
      ref: snapshot.ref,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      surveyDate: new Date(data.surveyDate),
      _preSurveyID: data._preSurveyID,
      _checkedIn: data._checkedIn?.toDate(),
      _checkedOut: data._checkedOut?.toDate(),
      _claimed: data._claimed?.toDate(),
      _used: data._used?.toDate(),
      _email: data._email,
      _sms: data._sms,
      _exported: data._exported,
      otherAnswers: _.omit(data, [
        'surveyDate',
        'first_name',
        'last_name',
        'email',
        'phone',
        '_preSurveyID',
        '_checkedIn',
        '_checkedOut',
        '_claimed',
        '_used',
        '_email',
        '_sms',
        '_exported',
        'start_time',
        'device_id',
        'event_id',
        'app_version',
        'abandoned',
      ]),
    };
  }
};

function CheckOutScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [checkOutMode, setCheckOutMode] = useState(true);
  const [thisEvent, setThisEvent] = useState<any>(null);
  const [surveysRef, setSurveysRef] = useState<CollectionReference<CheckUser>>();
  const [surveysQuery, setSurveysQuery] = useState<Query>();
  const [user, userLoading, userError] = useAuthState(auth);
  const [surveys, surveysLoading, surveysError] = useCollectionData(surveysQuery);

  useEffect(() => {
    console.error(surveysError);
  }, [surveysError]);

  useEffect(() => {
    console.error(userError);
  }, [userError]);

  useEffect(() => {
    console.log('surveysLoading', surveysLoading);
  }, [surveysLoading]);

  useEffect(() => {
    if (userLoading) return;

    // see if the user is logged in
    console.log('user', user);

    if (!user) {
      navigate('./login');
    }

    fetch('https://us-central1-latitude-lead-system.cloudfunctions.net/getSurvey', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ eventID: params.eventID }),
    }).then(response => {
      response.json().then((res) => {
        if (res.success && res.event._preEventID) {
          console.log('got event', res.event);
          setThisEvent(res.event);
          setSurveysRef(collection(db, `events/${res.event._preEventID}/surveys`).withConverter(checkUserConverter));

          // do more
        } else {
          // event closed or something, redirect up a directory
          navigate(`../`);
        }
      });
    }).catch(err => {
      Sentry.captureException(err);
      alert(err);
    });
  }, [userLoading]);

  useEffect(() => {
    if (!userLoading && surveysRef) {
      setSurveysQuery(
        query(surveysRef,
          where('_checkedOut', checkOutMode ? '==' : '!=', null),
          checkOutMode ? where('_checkedIn', '!=', null) : where('abandoned', '==', 0),
          orderBy('last_name'),
          orderBy('first_name')
        ))
        ;
    }
  }, [userLoading, checkOutMode, surveysRef]);

  const CheckOutRenderer = (props: ListViewItemProps) => {
    const [dialogVisible, setDialogVisible] = useState(false);
    const user: CheckUser = props.dataItem;

    return (
      <div
        className="k-listview-item row p-2 border-bottom align-middle"
        style={{ margin: 0, display: "flex", backgroundColor: props.index! % 2 ? "#ccc" : "#fff" }}
      >
        <div style={{ flex: 1 }}>
          <Avatar type="icon" themeColor="primary">
            <SvgIcon icon={userIcon} />
          </Avatar>
        </div>
        <div style={{ flex: 6 }}>
          <h2
            style={{ fontSize: 14, color: "#454545", marginBottom: 0 }}
            className="text-uppercase"
          >
            {user.first_name} {user.last_name}
          </h2>
          <div style={{ fontSize: 12, color: "#a0a0a0" }}>{user.email} {user.phone} {user._checkedOut && <>Checked Out: {user._checkedOut?.toLocaleDateString()} {user._checkedOut?.toLocaleTimeString()}</>}</div>
        </div>
        <div style={{ flex: 2 }}>
          {
            checkOutMode ?
              <Button themeColor="primary" onClick={() => setDialogVisible(true)}>Check Out</Button>
              :
              user._used ?
                <Button themeColor="primary" disabled>Already Completed Survey</Button>
                :
                <Button themeColor="primary" onClick={() => {
                  updateDoc(user.ref, {
                    _checkedOut: null,
                  }).then(() => {
                    console.log('undo check out');
                  }).catch((err: any) => {
                    console.error('Check Out Error', err);
                    Sentry.captureException(err);
                  });
                }
                }>Undo Check Out</Button>
          }
        </div>
        {dialogVisible &&
          <Dialog title="Check Out Person?" onClose={() => setDialogVisible(false)}>
            <p>{user.first_name} {user.last_name}</p>
            <JSONPretty id="json-pretty" data={JSON.stringify(user.otherAnswers)}></JSONPretty>
            <DialogActionsBar>
              <Button themeColor="warning" onClick={() => {
                setDialogVisible(false);
              }}>Cancel</Button>
              <Button themeColor="success" onClick={() => {
                updateDoc(user.ref, {
                  _checkedOut: new Date(),
                }).then(() => {
                  navigate(`/s/${params.eventID}`,
                    {
                      state: {
                        preSurveyID: user.id,
                      }
                    }
                  )
                  console.log('checked out');
                }).catch((err: any) => {
                  console.error('Check Out Error', err);
                  Sentry.captureException(err);
                });
                setDialogVisible(false);
              }}>Check Out</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </div>
    );
  }

  return (
    <>
      <h1 className="text-center my-3 title">Check Out {(thisEvent && <> - {thisEvent?.name}</>)}</h1>

      {(userLoading || !thisEvent) && <><br /><br /><p>Loading...</p></>}

      {
        surveys &&
        <div style={{ marginTop: '3em' }}>
          <ButtonGroup>
            <Button themeColor={checkOutMode ? "primary" : "base"} onClick={() => setCheckOutMode(true)}>Check Out</Button>
            <Button themeColor={!checkOutMode ? "primary" : "base"} onClick={() => setCheckOutMode(false)}>View Checked Out</Button>
          </ButtonGroup>
          <ListView
            data={surveys}
            item={CheckOutRenderer}
            style={{ width: "100%", marginTop: '1em' }}
          />
        </div>
      }
    </>
  );
}

export default CheckOutScreen;
