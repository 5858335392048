import { useEffect, useState } from 'react';

import { useNavigate, useParams } from "react-router-dom";
import { getFirestore, doc, getDoc, collection, query, getDocs } from "firebase/firestore";
import auth from '../services/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
import app from '../services/firebase';

import { Model, Question, slk } from "survey-core";
import { Tabulator } from 'survey-analytics/survey.analytics.tabulator';

import 'survey-analytics/survey.analytics.min.css';
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'survey-analytics/survey.analytics.tabulator.min.css';

slk(
  "NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wNy0xOSwyPTIwMjUtMDctMTksND0yMDI1LTA3LTE5"
);

function DashboardScreen() {
  const navigate = useNavigate();
  const params = useParams();
  const [user, userLoading, userError] = useAuthState(auth);
  const [thisEvent, setThisEvent] = useState<any>();
  const [thisSurvey, setThisSurvey] = useState<Model>();
  const [allQuestions, setAllQuestions] = useState<Question[]>([]);
  const [allAnswers, setAllAnswers] = useState<any[]>();
  // const [vizPanel, setVizPanel] = useState<VisualizationPanel>();
  const [surveyDataTable, setSurveyDataTable] = useState<Tabulator>();

  const vizPanelOptions = {
    allowHideQuestions: false
  }

  useEffect(() => {
    console.error(userError);
  }, [userError]);

  const db = getFirestore(app);
  const eventID: string = params.eventID!;

  useEffect(() => {
    if (userLoading) return;

    // see if the user is logged in
    console.log('user', user);

    if (!user) {
      navigate('./login');
    }

    // get the event
    const eventRef = doc(db, "events", eventID);
    getDoc(eventRef).then((doc) => {
      const incomingEvent = doc.data();
      setThisEvent(incomingEvent);

      const surveyJSON = JSON.parse(incomingEvent?.questions);

      // add a couple fields to the survey json
      surveyJSON.pages[0].elements.unshift(
        {
          "type": "text",
          "name": "id",
          "visible": false,
        },
        {
          "type": "text",
          "name": "survey_date",
          "inputType": "datetime-local",
          "visible": false,
        },
      )
      surveyJSON.pages[surveyJSON.pages.length - 1].elements.push(
        {
          "type": "text",
          "name": "end_time",
          "inputType": "datetime-local",
          "visible": false,
        },
        {
          "type": "text",
          "name": "_utm",
          "visible": false,
        },
        {
          "type": "text",
          "name": "_referrer",
          "visible": false,
        },
        {
          "type": "text",
          "name": "_timeZone",
          "visible": false,
        },
        {
          "type": "text",
          "name": "_language",
          "visible": false,
        },
      )
      if (incomingEvent?._preEventID) {
        surveyJSON.pages[surveyJSON.pages.length - 1].elements.push(
          {
            "type": "text",
            "name": "_preSurveyID",
            "visible": false,
          },
        )
      }

      const survey = new Model(surveyJSON);

      setThisSurvey(survey);
      setAllQuestions(survey.getAllQuestions());

      // get the answers
      const answersRef = collection(db, "events", eventID, "surveys");
      const q = query(answersRef);
      getDocs(q).then((querySnapshot) => {
        const answers: any[] = [];

        querySnapshot.forEach((doc) => {
          answers.push({
            id: doc.id,
            _preEventID: incomingEvent?._preEventID,
            ...doc.data()
          });
        });

        setAllAnswers(answers);
      });
    });
  }, [userLoading]);

  useEffect(() => {
    if (!thisSurvey || !allAnswers) return;

    // const vizPanel = new VisualizationPanel(
    //   thisSurvey.getAllQuestions(),
    //   allAnswers,
    //   vizPanelOptions
    // );
    // setVizPanel(vizPanel);
    const surveyDataTable = new Tabulator(
      thisSurvey,
      allAnswers,
      {
        downloadButtons: ['xlsx', 'csv', 'pdf'],
        useNamesAsTitles: true,
      }
    );
    setSurveyDataTable(surveyDataTable);

  }, [thisSurvey, allAnswers]);

  useEffect(() => {
    if (!surveyDataTable) return;

    surveyDataTable.render("surveyDataTable");
    return () => {
      document.getElementById("surveyDataTable")!.innerHTML = "";
    }
  }, [surveyDataTable]);

  return (
    <div>
      <h1>{thisSurvey?.title} (Responders: {allAnswers?.length})</h1>

      <div id="surveyDataTable" />
    </div>
  );
}

export default DashboardScreen;
