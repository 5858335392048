import { initializeApp } from "firebase/app";
import type { FirebaseOptions } from "firebase/app";

const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyAGX-fDz0xFhlEjuWSEK-2GB6W1R61TIuo",
    authDomain: "latitude-lead-system.firebaseapp.com",
    projectId: "latitude-lead-system",
    storageBucket: "latitude-lead-system.appspot.com",
    messagingSenderId: "846031493147",
    appId: "1:846031493147:web:097f695ea7e214a80b80be",
    measurementId: "G-2NHQNB0M5R"
};

const app = initializeApp(firebaseConfig);

export default app;